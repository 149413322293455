<template>
  <OneColumnTemplate
    :showSidebar="adminView ? false : true"
    :isOrgSidebar="true"
    class="Restyle"
    :style="adminView ? 'background: transparent !important;' : ''"
  >
    <template v-slot:ColumnContent>
      <div class="PageContentWrapper">
        <div class="Header">
          <!-- <div class="ButtonBack" to="/org/students-list">
            <div class="ButtonBackIMG"></div>
          </div> -->

          <span class="HeaderTitle">База выпускников</span>
        </div>

        <div class="TableWrapper">
          <div class="Filters">
            <input
              v-model="searchText"
              class="FilterInput HideOnMobileByDefault"
              :style="showSearchOnMobile ? 'display: block;' : ''"
              type="text"
              placeholder="Поиск по студентам"
              @input="filtersUpdated = true"
            />

            <div
              class="Filter ShowHideInputM"
              v-if="!showSearchOnMobile"
              @click="showSearchOnMobile = true"
            >
              <div class="SInputIMG"></div>
            </div>

            <div
              class="Filter ShowHideInputM"
              v-if="showSearchOnMobile"
              @click="showSearchOnMobile = false"
            >
              <div class="FilterIMG"></div>
            </div>

            <div
              v-for="(f, findx) in filters"
              :key="`filter_${findx}`"
              @click.self="
                () => {
                  if (openedMenu === f.name) {
                    openedMenu = null;
                  } else {
                    openedMenu = f.name;
                  }
                }
              "
              :class="
                `Filter noselect HideOnMobileByDefault ${
                  openedMenu === f.name ? 'MFActive' : ''
                } ${f.selected.length != 0 ? 'NotEmpty' : ''}`
              "
              :style="
                `${openedMenu === f.name ? 'z-index: 5;' : ''} ${
                  !showSearchOnMobile ? 'display: flex;' : ''
                }`
              "
            >
              <span
                @click.self="
                  () => {
                    if (openedMenu === f.name) {
                      openedMenu = null;
                    } else {
                      openedMenu = f.name;
                    }
                  }
                "
                >{{ f.name }}</span
              >
              <div
                @click="
                  () => {
                    if (openedMenu === f.name) {
                      openedMenu = null;
                    } else {
                      if (f.selected.length !== 0) {
                        f.selected = [];
                      } else {
                        openedMenu = f.name;
                      }
                    }
                  }
                "
                :class="
                  f.selected.length === 0 || openedMenu === f.name
                    ? 'FilterIMG'
                    : 'RemoveFiltersIcon'
                "
              ></div>

              <div v-if="openedMenu === f.name" class="DropdownMenu">
                <div
                  class="DDMElem"
                  @click="
                    () => {
                      f.selected = [];
                      filtersUpdated = true;
                    }
                  "
                >
                  <span>Очистить выбор</span>
                </div>
                <div
                  v-for="(fe, feindx) in f.list"
                  :key="`filter_item_${feindx}_${fe}`"
                  :class="{
                    DDMElem: true,
                    Active: filterIncluded(f.code, fe) !== -1,
                  }"
                  @click="
                    () => {
                      addOrRemoveFilter(f.code, fe);
                      filtersUpdated = true;
                    }
                  "
                >
                  <span>{{ fe }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="Table">
            <div class="TElem THeader">
              <div
                v-for="(th, thindx) in tableHeader"
                :key="`head-${thindx}`"
                class="TEPlace"
              >
                <span class="TEText">
                  {{ th }}
                </span>
              </div>
            </div>

            <template v-if="!pageIsLoading">
              <div
                v-for="(User, Uindx) in showStudents"
                :key="`${User.id}${Uindx}`"
                class="TElem"
              >
                <div class="TEPlace">
                  <span
                    v-if="adminView"
                    class="TEText AutoHyphens"
                    lang="ru"
                    :title="User.id"
                  >
                    {{ getFIO(User) }}
                  </span>
                  <span v-else class="TEText AutoHyphens" lang="ru">
                    {{ getFIO(User) }}
                  </span>
                </div>
                <div class="TEPlace">
                  <span class="TEText AutoHyphens" lang="ru">
                    {{ getRegion(User.MapUniver) }}
                  </span>
                </div>
                <div class="TEPlace">
                  <span class="TEText AutoHyphens" lang="ru">
                    {{ getUniver(User.MapUniver) }}
                  </span>
                </div>
                <div class="TEPlace">
                  <span class="TEText">
                    {{ calculateCurrentCourse(User) }}
                  </span>
                </div>
                <div class="TEPlace">
                  <span class="TEText AutoHyphens" lang="ru">
                    {{ getSkillsInline(User.user_skills) }}
                  </span>
                </div>
                <div class="TEPlace">
                  <span v-if="!adminView" class="TEText AutoHyphens" lang="ru">
                    {{ User?.gjs == null ? "Не указано" : User?.gjs }}
                  </span>

                  <el-select
                    v-if="adminView && User.gjs != null"
                    v-model="User.gjs"
                    @change="handleChangeStatus(User)"
                    class="RDSelectField"
                  >
                    <el-option value="в поисках работы"
                      >в поисках работы</el-option
                    >
                    <el-option value="трудоустроен">трудоустроен</el-option>
                    <el-option value="не ищет работу">не ищет работу</el-option>
                  </el-select>
                </div>
                <div class="TEPlace">
                  <span class="TEText">{{ formatDate(User.baseDate) }}</span>
                </div>
                <div
                  v-if="adminView && User.gjs != null"
                  class="TEPlace TEPLink noselect"
                  :key="`DownloadCard-${User.id}-${Uindx}-${getFIO(User)}`"
                  @click="openCertificateModal(User.id)"
                >
                  <span class="TEText">
                    <div class="RLink">
                      <span class="TEPLText"> Скачать сертификат </span>
                      <div class="SRLinkIMG"></div>
                    </div>
                  </span>
                </div>
                <div
                  v-if="!adminView"
                  :key="`UserLinkCertificate-${User.id}`"
                  class="TEPlace TEPLink"
                >
                  <span class="TEText">
                    <router-link
                      class="RLink"
                      :to="`/org/students-rating?user=${User.id}`"
                    >
                      <span class="TEPLText"> Карточка выпускника </span>
                      <div class="SRLinkIMG"></div>
                    </router-link>
                  </span>
                </div>
              </div>
            </template>
            <span
              v-if="!pageIsLoading && showStudents.length === 0"
              class="NoStudentsFound"
              >Список пуст</span
            >

            <div v-show="pageIsLoading" class="lds-dual-ring"></div>
          </div>

          <div class="BackgroundsCollection">
            <div class="BGLight"></div>
          </div>
        </div>

        <div class="MobileCards">
          <div
            v-for="(User, Uindx) in showStudents"
            :key="`${User.id}${Uindx}`"
            class="MCard"
          >
            <span class="SCity">{{ getRegion(User.MapUniver) }}</span>

            <div class="SInfo">
              <span class="SFIO">{{ getFIO(User) }}</span>
              <span class="SAInfo"
                >{{
                  calculateCurrentCourse(User) == "Не указано"
                    ? "Курс не указан, "
                    : `${calculateCurrentCourse(User)} курс, `
                }}
                {{ getUniver(User.MapUniver) }}</span
              >
            </div>

            <span class="SCompetHeader">Компетенции:</span>
            <span class="SCompetList">{{
              getSkillsInline(User.user_skills)
            }}</span>

            <span class="SCompetHeader">Статус:</span>
            <span class="SCompetList">
              {{ User?.gjs == null ? "Не указано" : User?.gjs }}</span
            >

            <span class="SCompetHeader">Дата попадания в базу:</span>
            <span class="SCompetList">{{ formatDate(User.baseDate) }}</span>

            <router-link
              class="SRLink"
              :to="`/org/students-rating?user=${User.id}`"
            >
              <span class="SCompetList"> Карточка выпускника </span>

              <div class="SRLinkIMG Active"></div>
            </router-link>
          </div>
        </div>

        <div class="Pagination">
          <div
            :style="
              paginationList.canClickOnPrevButton
                ? ''
                : 'cursor: default; opacity: 0.45;'
            "
            class="PBPrev"
            @click="
              () => {
                if (paginationList.canClickOnPrevButton) {
                  pagination.page -= 1;
                }
              }
            "
          >
            <div class="PBPrevIMG"></div>
          </div>

          <div class="PPagesList">
            <div
              v-for="(p, pindx) in paginationList.buttons"
              :key="`pag_${pindx}`"
              :class="
                `PBIndex noselect ${
                  pagination.page === p.val ? 'PBActive' : ''
                }`
              "
              @click="pagination.page = p.val"
            >
              <span>{{ p.show_three_dots ? "..." : p.val }}</span>
            </div>
          </div>

          <div
            :style="
              paginationList.canClickOnNextButton
                ? ''
                : 'cursor: default; opacity: 0.45;'
            "
            class="PBNext"
            @click="
              () => {
                if (paginationList.canClickOnNextButton) {
                  pagination.page += 1;
                }
              }
            "
          >
            <div class="PBNextIMG"></div>
          </div>
        </div>

        <NewStyledModal
          class="Styled-Modal"
          :showModal="certificate_data.show"
          :hideModal="closeCertificateModal"
          :key="`Cert-${certificate_data.show}-${certificate_data.userID}`"
        >
          <template v-slot:ModalContent>
            <div class="Offer-Modal" v-if="certificate_data.userID != null">
              <span class="Offer-Modal-Title"> Скачать сертификат </span>

              <div class="Offer-Modal-Content">
                <div class="Input-Floating-Label">
                  <input
                    class="Input-styled"
                    v-model="certificate_data.start_time"
                    type="date"
                    id="start"
                    name="trip-start"
                  />

                  <span class="Input-Placeholder noselect">Дата начала</span>
                </div>

                <div class="Input-Floating-Label">
                  <input
                    class="Input-styled"
                    v-model="certificate_data.end_time"
                    type="date"
                    id="start"
                    name="trip-start"
                  />

                  <span class="Input-Placeholder noselect">Дата окончания</span>
                </div>

                <div class="Input-Floating-Label">
                  <input
                    v-model="certificate_data.vuz"
                    type="text"
                    :class="{
                      'Input-styled': true,
                      'Input-highligt-error':
                        certificate_data.vuz == 'ВУЗ у студента не указан',
                    }"
                  />
                  <span class="Input-Placeholder noselect">ВУЗ</span>
                </div>

                <div class="Input-Floating-Label">
                  <select
                    v-model="certificate_data.taskID"
                    :class="{
                      'Input-styled': true,
                      'Input-highligt-error': certificate_data.taskID == null,
                    }"
                  >
                    <option :value="null">Не указано</option>
                    <option
                      v-for="(job, jindx) in certificate_data.userTasks"
                      :key="`catInList-${jindx}-${job.id}`"
                      :value="job.id"
                    >
                      {{ job.name }}
                    </option>
                  </select>
                  <span class="Input-Placeholder noselect"
                    >Выберите задачу</span
                  >
                </div>

                <span
                  class="Offer-Text-Error"
                  style="min-width: none; width: 560px; max-width: 100%"
                  >После открытия страницы в новой вкладке, сертификат по ссылке
                  будет доступен лишь 15 секунд. Если не закрывать вкладку с
                  сертификатом его можно скачать в любой момент. Если вдруг
                  закрыли вкладку, можно заново нажать скачать</span
                >
              </div>

              <div class="Offer-Modal-Buttons noselect">
                <span class="Offer-Button-Prev" @click="closeCertificateModal"
                  >Закрыть</span
                >
                <span
                  class="Offer-Button-Prev"
                  :style="
                    certificate_data.vuz != 'ВУЗ у студента не указан' &&
                    certificate_data.taskID != null
                      ? ''
                      : 'opacity: 0.5'
                  "
                  @click="
                    () => {
                      if (
                        certificate_data.vuz != 'ВУЗ у студента не указан' &&
                        certificate_data.taskID != null
                      ) {
                        downloadCertificate();
                      }
                    }
                  "
                  >Скачать</span
                >
              </div>
            </div>
          </template>
        </NewStyledModal>
      </div>
    </template>
  </OneColumnTemplate>
</template>

<script>
import OneColumnTemplate from "@/components/ProfileTemplates/OneColumn.vue";

import Fuse from "fuse.js";

import {
  getStudentsList,
  downloadFileWithStudents,
  getPDFCertificate,
} from "@/api/company.js";
import { getSections } from "@/api/competence.js";
import { mapGetters } from "vuex";
import {
  getRegionAndUniverList,
  getStudentsStatusForArray,
  getStudentsReviewDateForArray,
  changeStudentStatusByID,
  getUserInfoForRating,
} from "@/api/user.js";

import NewStyledModal from "@/components/CustomElements/NewStyledModal.vue";

export default {
  data() {
    return {
      tableHeader: [
        "ФИО",
        "Регион",
        "ВУЗ",
        "Курс",
        "Компетенции",
        "Статус",
        "Дата попадания в базу",
        "",
      ],

      studentsList: [],

      filters: {
        region: {
          name: "Регион",
          list: [],
          selected: [],
          code: "region",
        },
        vuz: {
          name: "ВУЗ",
          list: [],
          selected: [],
          code: "vuz",
        },
        lvl: {
          name: "Курс",
          list: ["1", "2", "3", "4", "5"],
          selected: [],
          code: "lvl",
        },
        hasDirection: {
          name: "Направление",
          list: [],
          selected: [],
          code: "hasDirection",
        },
      },
      searchText: "",
      openedMenu: null,

      pagination: {
        show_max_pages: 3, //Сколько кнопок с навигацией по "страницам" отображаем, включая текущую страницу
        show_max_items: 6, //Сколько элементов отображаем из всего списка
        page: 1,
      },
      filtersUpdated: false,

      pageIsLoading: true,
      showSearchOnMobile: false,

      adminView: false,

      limit: 50,
      loading_page: 1,

      certificate_data: {
        show: false,
        userID: null,
        taskID: null,

        userTasks: [],
        vuz: "",

        start_time: Date.now(),
        end_time: Date.now(),
      },
    };
  },
  name: "GetStudentsList",
  components: { OneColumnTemplate, NewStyledModal },
  async created() {
    if (this?.user?.parent_company[0]?.type === "ВУЗ") {
      this.$router.push({ name: "OrgGetStudentsListForVUZ" });
      return 0;
    }

    if (this?.user?.role?.name === "admin") {
      this.adminView = true;
    }

    let getSectionsResponse = await getSections();
    this.filters.hasDirection.list = getSectionsResponse.data.map(
      (x) => x.name
    );

    let regionsAndUniversResponse = await getRegionAndUniverList();
    this.filters.region.list = regionsAndUniversResponse.data
      .map((x) => x.name)
      .filter((x) => x != "Другое");

    this.filters.vuz.list = regionsAndUniversResponse.data
      .map((x) => {
        return x.univers.map((i) => i.name);
      })
      .flat()
      .filter((x) => x != "Другое");

    await this.loadStudentsList();

    document.addEventListener("click", this.hideFilterMenuIfOpened);
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    showStudents() {
      return this.getPieceOfList(
        this.studentsAfterFilters,
        this.pagination.show_max_items,
        this.pagination.page
      );
    },

    studentsAfterFilters() {
      if (this.studentsList == null || this.studentsList.length === 0) {
        return [];
      }

      if (this.filtersUpdated) {
        this.pagination.page = 1;
        this.filtersUpdated = false;
      }

      let filtersUsed =
        this.filters.region.selected.length != 0 ||
        this.filters.vuz.selected.length != 0 ||
        this.filters.lvl.selected.length != 0 ||
        this.filters.hasDirection.selected.length != 0 ||
        this.searchText !== "";

      if (!filtersUsed) {
        return this.studentsList;
      }

      let filteredResult = JSON.parse(JSON.stringify(this.studentsList));

      if (this.searchText !== "") {
        // Опции для настройки поиска
        const options = {
          isCaseSensitive: false,
          includeScore: false,
          shouldSort: false,
          threshold: 0.25,
          keys: ["surname", "name", "patronymic"], // Поля, по которым будет производиться поиск
        };

        // Инициализация экземпляра fuse
        const fuse = new Fuse(filteredResult, options);

        // Выполнение поиска
        let result = fuse.search(this.searchText);
        result = result.map((x) => x.item);
        filteredResult = result;
        console.log(result);
      }

      if (this.filters.region.selected.length != 0) {
        filteredResult = filteredResult.filter((x) =>
          this.filters.region.selected.includes(this.getRegion(x.MapUniver))
        );
      }

      if (this.filters.vuz.selected.length != 0) {
        filteredResult = filteredResult.filter((x) =>
          this.filters.vuz.selected.includes(this.getUniver(x.MapUniver))
        );
      }

      if (this.filters.lvl.selected.length != 0) {
        filteredResult = filteredResult.filter((x) =>
          this.filters.lvl.selected.includes(
            `${this.calculateCurrentCourse(x)}`
          )
        );
      }

      if (this.filters.hasDirection.selected.length != 0) {
        filteredResult = filteredResult.filter((x) =>
          this.getSkillsArray(x.user_skills).some((element) =>
            this.filters.hasDirection.selected.includes(element)
          )
        );
      }

      return filteredResult;
    },

    paginationList() {
      let buttons_array = [];

      // Суммарное кол-во страниц
      let pagesCounter = Math.ceil(
        this.studentsAfterFilters.length / this.pagination.show_max_items
      );
      // let pagesCounter = Math.ceil(41 / this.pagination.show_max_items); //test

      if (pagesCounter === 0) {
        pagesCounter = 1;
      }

      //Перечисление всех страниц 1, 2, 3 ... N
      let pagesList = this.countFromXToN(1, pagesCounter);

      //Индекс текущей страницы в массиве
      let currentPageIndex = this.pagination.page - 1;

      //Перечисление, какие страницы мы показываем в виде кнопок с цифрами
      let pagesButtonForShow;
      if (currentPageIndex === 0) {
        pagesButtonForShow = pagesList.slice(
          currentPageIndex,
          currentPageIndex + this.pagination.show_max_pages
        );
      } else {
        pagesButtonForShow = pagesList.slice(
          currentPageIndex - 1,
          currentPageIndex - 1 + this.pagination.show_max_pages
        );
      }

      let canClickOnPrevButton = this.pagination.page != 1;
      let canClickOnNextButton = this.pagination.page != pagesCounter;

      //Разница между последней отображаемой кнопкой и последней страницей
      let difference =
        pagesCounter - pagesButtonForShow[pagesButtonForShow.length - 1];

      //Чтобы показывать 3 точки и последнюю страницу, разница должна быть больше 2
      let show3DotsAndLastElement = difference > 2;

      //Подготавливаем
      for (let pageNumber of pagesButtonForShow) {
        buttons_array.push({
          show_three_dots: false, //true / false
          val: pageNumber,
        });
      }

      if (show3DotsAndLastElement) {
        buttons_array.push({
          show_three_dots: true,
          val:
            pagesButtonForShow[pagesButtonForShow.length - 1] +
            Math.ceil(difference / 2),
        });
        buttons_array.push({
          show_three_dots: false,
          val: pagesCounter,
        });
      } else {
        //Показываем оставшиеся страницы
        if (
          !pagesButtonForShow.includes(pagesCounter - 1) &&
          currentPageIndex < pagesCounter - 1
        ) {
          buttons_array.push({
            show_three_dots: false, //true / false
            val: pagesCounter - 1,
          });
        }

        if (!pagesButtonForShow.includes(pagesCounter)) {
          buttons_array.push({
            show_three_dots: false, //true / false
            val: pagesCounter,
          });
        }

        if (buttons_array.length < this.pagination.show_max_pages + 2) {
          let startIndex = buttons_array[0].val - 1;
          let pages = this.countFromXToN(
            1,
            this.pagination.show_max_pages + 2 - buttons_array.length
          );

          for (let i of pages) {
            let __page = startIndex - i + 1;
            if (__page >= 1) {
              buttons_array.unshift({
                show_three_dots: false,
                val: __page,
              });
            }
          }
        }
      }

      return {
        canClickOnPrevButton: canClickOnPrevButton,
        canClickOnNextButton: canClickOnNextButton,
        buttons: buttons_array,
      };
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return "Не указано";
      return new Date(date).toLocaleDateString("ru-RU");
    },

    async loadStudentsList() {
      let allUsersResp = await getStudentsList(
        this.limit,
        (this.loading_page - 1) * this.limit
      );
      this.loading_page += 1;

      let temp_arr = allUsersResp.data
        .map((user) => {
          const userSkills = user.user_cells.map(
            (cell) => cell.level.degree.section.name
          );
          const uniqueSkills = [...new Set(userSkills)];
          const userSkillsCount = uniqueSkills.map((skill) => {
            const count = userSkills.filter((s) => s === skill).length;
            return { name: skill, count: count };
          });
          return {
            id: user.id,
            surname: user.surname,
            name: user.name,
            patronymic: user.patronymic,
            user_skills: userSkillsCount,
            user_skills_count: uniqueSkills.length,
            MapUniver: user.MapRegion,
            lvl: user.lvl,
            createdAt: user.createdAt,
          };
        })
        .sort((a, b) => b.user_skills_count - a.user_skills_count)
        .filter((x) => {
          const regex = /(test|тест)/i;
          return !(
            regex.test(x.surname) ||
            regex.test(x.name) ||
            regex.test(x.patronymic)
          );
        });

      this.studentsList = this.studentsList.concat(temp_arr);

      if (allUsersResp.data.length < this.limit) {
        let graduatesJobStatusResponse = await getStudentsStatusForArray({
          students: this.studentsList.map((x) => x.id),
        });
        let reviewDateResponse = await getStudentsReviewDateForArray({
          students: this.studentsList.map((x) => x.id),
        });
        this.studentsList = this.studentsList
          .map((user) => {
            return {
              ...user,
              gjs: graduatesJobStatusResponse?.data?.[user.id],
              baseDate: reviewDateResponse?.data?.[user.id],
            };
          })
          .sort((a, b) => {
            const dateA = a.baseDate ? new Date(a.baseDate) : 0;
            const dateB = b.baseDate ? new Date(b.baseDate) : 0;
            return dateB - dateA;
          });

        this.pageIsLoading = false;
      } else {
        await this.loadStudentsList();
      }
    },

    async openCertificateModal(userID) {
      try {
        let response = await getUserInfoForRating(userID);
        let userData = response.data;
        this.certificate_data.userTasks = userData.jobs_completed;

        this.certificate_data.userID = userID;
        this.certificate_data.vuz = this.getUserRegionAndUniver(userData);
        this.certificate_data.show = true;

        this.certificate_data.start_time = new Date(Date.now())
          .toISOString()
          .split("T")[0];
      } catch (e) {
        alert("Ошибка получения списка задач пользователя");
        return 0;
      }
    },
    closeCertificateModal() {
      this.certificate_data.show = false;
      this.certificate_data.userID = null;
      this.certificate_data.taskID = null;
      this.certificate_data.userTasks = [];
      this.certificate_data.vuz = "";
    },
    getUserRegionAndUniver(userData) {
      if (
        userData?.user?.MapRegion == null ||
        userData.user.MapRegion.univer_id == null
      ) {
        return "ВУЗ у студента не указан";
      }

      if (userData.user.MapRegion.UsersChoosedUniver.name === "Другое") {
        return userData.user.MapRegion.univer_other;
      } else {
        return userData.user.MapRegion.UsersChoosedUniver.name;
      }
    },

    async downloadCertificate() {
      let getLinkResponse = await getPDFCertificate(
        this.certificate_data.userID,
        this.certificate_data.taskID,
        this.certificate_data.vuz,
        this.certificate_data.start_time,
        this.certificate_data.end_time
      );

      if (getLinkResponse.data === -1) {
        alert("Ошибка на сервере");
        return 0;
      }

      let link = document.createElement("a");
      link.href = `${process.env.VUE_APP_BACK}${getLinkResponse.data}`;
      link.download = "file.pdf"; // Укажите корректное расширение файла
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    calculateCurrentCourse(student) {
      switch (student.lvl) {
        case null:
        case 0: {
          return "Не указано";
        }
        case 4: {
          return 4;
        }
        case 5: {
          return 5;
        }
      }

      const currentDate = new Date(); // Текущая дата
      const registrationDate = new Date(student.createdAt); // Дата регистрации студента
      const registrationYear = registrationDate.getFullYear();
      const registrationMonth = registrationDate.getMonth(); // Месяц регистрации (0 — январь, 11 — декабрь)

      let currentCourse = student.lvl; // Текущий курс студента

      // Если студент зарегистрировался до июля (месяц меньше 6), то его курс должен увеличиться на 1
      if (registrationMonth < 6) {
        currentCourse += currentDate.getFullYear() - registrationYear;
      } else {
        // Если студент зарегистрировался в июле или позже
        currentCourse += currentDate.getFullYear() - registrationYear - 1;
      }

      return Math.min(currentCourse, 4);
    },
    getSkillsInline(skillsArr) {
      return skillsArr
        .map((skill) => `${skill.name} (${skill.count})`)
        .join(", ");
    },
    getSkillsArray(skillsArr) {
      return skillsArr.map((skill) => skill.name);
    },
    getRegion(MapUniver) {
      if (MapUniver == null) {
        return "Не указано";
      }

      if (MapUniver.UsersChoosedUniver != null) {
        if (MapUniver.UsersChoosedUniver.region.name == "Другое") {
          return MapUniver.region_other;
        }
        return MapUniver.UsersChoosedUniver.region.name;
      }

      if (MapUniver.region_other == null || MapUniver.region_other == "") {
        return "Не указано";
      } else {
        return MapUniver.region_other;
      }
    },
    getUniver(MapUniver) {
      if (MapUniver == null) {
        return "Не указано";
      }

      if (MapUniver.UsersChoosedUniver != null) {
        if (MapUniver.UsersChoosedUniver.name == "Другое") {
          return MapUniver.univer_other;
        }
        return MapUniver.UsersChoosedUniver.name;
      }

      if (MapUniver.univer_other == null || MapUniver.univer_other == "") {
        return "Не указано";
      } else {
        return MapUniver.univer_other;
      }
    },
    getFIO(userData) {
      const name = userData.name == null ? "" : userData.name.trim();
      const surname = userData.surname == null ? "" : userData.surname.trim();

      if (name !== "" && surname !== "") {
        if (this.adminView) {
          return `${name} ${surname}`;
        }

        return `${name} ${surname.charAt(0).toUpperCase()}.`;
      }

      if (name !== "" || surname !== "") {
        if (this.adminView) {
          return name !== "" ? name : surname;
        }

        return name !== "" ? name : `${surname.charAt(0).toUpperCase()}.`;
      }

      return "Не указано";
    },

    getEmptyStringIfNull(str) {
      return str == null || str == "" ? "" : str;
    },

    async downloadXLSXFile() {
      let VuzName = this.user.parent_company[0].name;
      let Students = this.studentsList.filter((x) => {
        let Student_VUZ = x?.MapUniver?.UsersChoosedUniver?.name;
        if (Student_VUZ != null) {
          return (
            Student_VUZ.replaceAll(" ", "") === VuzName.replaceAll(" ", "")
          );
        }
      });

      try {
        let _file_resp = await downloadFileWithStudents({
          students: Students,
        });

        const fileLink = document.createElement("a");
        fileLink.href = _file_resp.data;
        fileLink.setAttribute("download", _file_resp.data);
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();
      } catch (e) {
        console.log(e);
      }
    },

    //pagination
    countFromXToN(x, n) {
      let res = [];
      if (x <= n) {
        for (let i = x; i <= n; i++) {
          res.push(i);
        }
      } else {
        for (let i = x; i >= n; i--) {
          res.push(i);
        }
      }
      return res;
    },
    getPieceOfList(arr, count, page) {
      let start = count * (page - 1);
      return arr.slice(start, start + count);
    },

    filterIncluded(filterName, val) {
      return this.filters[filterName].selected.indexOf(val);
    },
    addOrRemoveFilter(filterName, val) {
      let index = this.filterIncluded(filterName, val);
      if (index === -1) {
        this.filters[filterName].selected.push(val);
      } else {
        this.filters[filterName].selected.splice(index, 1);
      }
    },

    hideFilterMenuIfOpened(event) {
      if (this.openedMenu == null) {
        return 0;
      }

      let currentElement = event.target;

      let foundFilter = false;

      // Обходим родителей до #app
      while (currentElement && currentElement.id !== "app") {
        if (!foundFilter && currentElement.classList.contains("Filter")) {
          foundFilter = true; // Нашли .Filter
        } else if (
          foundFilter &&
          currentElement.classList.contains("DDMElem")
        ) {
          break; // Порядок найден, можно остановиться
        }
        currentElement = currentElement.parentElement; // Поднимаемся вверх по DOM
      }

      if (!foundFilter) {
        this.openedMenu = null;
      }
    },

    async handleChangeStatus(user) {
      await changeStudentStatusByID({
        id: user.id,
        gjs: user.gjs,
      });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

.BackgroundsCollection {
  position: absolute;
  display: block;

  inset: 0;

  width: 100%;
  height: 100%;

  z-index: 1;
}
.BackgroundsCollection > * {
  pointer-events: none;
}
.TableWrapper > *:not(.BackgroundsCollection) {
  z-index: 2;
}

.BGLight {
  position: absolute;
  display: block;

  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-image: url("./../../assets/img/ReDesign/pages/rating_student/bg_table_light.svg");
  background-position: 50%, 0%;
  background-repeat: no-repeat;
  background-size: 40%;

  filter: blur(40px);
}

.Restyle:deep(.ProfileContent) {
  overflow: hidden;
}
.Restyle:deep(.ProfileBlocks) {
  overflow: auto;
}
.Restyle:deep(.Sidebar-Wrapper) {
  display: none;
}

.Restyle {
  background: #232659 !important;
}

.PageContentWrapper {
  position: relative;
  display: flex;

  margin: 0% auto;
  padding-right: 15px;

  width: 100%;
  max-width: 1520px;
  height: fit-content;
  min-height: 100%;

  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;

  overflow: visible;
}

.Header {
  position: relative;
  display: flex;

  margin-top: 30px;

  width: fit-content;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 26px;
}
.ButtonBack {
  position: relative;
  display: block;

  width: 52px;
  height: 52px;

  transition: 0.25s;

  border-radius: var(--o-s-global-border-radius);
  background-color: #34387c;
}
.ButtonBack:hover {
  background-color: #5445a4;
}
.ButtonBackIMG {
  position: relative;
  display: block;

  width: 100%;
  height: 100%;

  background: url("./../../assets/img/RatingStudent/arrow_back.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.HeaderTitle {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;

  color: white;
}

.TableWrapper {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}
.Filters {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;

  z-index: 500 !important;
}

.Filter > * {
  margin: auto 0%;
}
.Filter {
  position: relative;
  display: flex;

  cursor: pointer;

  padding: 14px 24px;

  width: fit-content;
  max-width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  border-radius: var(--o-s-global-border-radius);
  background-color: #423b8b;
}
.FilterIMG {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../assets/img/ReDesign/interface_icons/filter_list_icon_16px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}
.Filter > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: #ffffffe5;
}
.FilterInput {
  position: relative;
  display: block;

  padding: 14px 20px;

  width: 100%;
  max-width: 328px;
  height: 52px;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #ffffffe5;

  outline: transparent;
  background-color: transparent;

  border-radius: var(--o-s-global-border-radius);
  border: 1px solid #8f63f03d;
}

.FilterInput::placeholder {
  color: #ffffff66;
}

.Table {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 2px;

  border-radius: var(--o-s-global-border-radius);
  overflow: hidden;
}
.TElem {
  position: relative;
  display: grid;

  width: 100%;
  height: fit-content;

  grid-template-columns:
    minmax(0, 1fr) minmax(0, 1fr) minmax(0, 0.6fr) minmax(0, 0.4fr)
    minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1.3fr);

  gap: 2px;
}

.TEPLText {
  display: block;
  white-space: normal;
  word-break: break-word;
}

.TElem > * {
  margin-top: auto;
  margin-bottom: auto;
}
.THeader .TEPlace {
  padding: 12px 20px;
}
.TEPlace {
  position: relative;
  display: flex;

  padding: 32px 20px;

  width: 100%;
  height: 100%;

  transition: 0.25s;

  background-color: #292d68cc;
}
.TEPlace.TEPLink {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TEPlace.TEPLink:hover {
  background-color: #8e63ef;
}
.TEPlace.TEPLink:hover .TEPLText {
  color: #eaebf2;
}
.TEPlace.TEPLink:hover .SRLinkIMG {
  filter: brightness(0) saturate(100%) invert(84%) sepia(11%) saturate(39%)
    hue-rotate(201deg) brightness(111%) contrast(91%);
}
.TEPLText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: center;

  color: white;

  transition: 0.25s;
}
.THeader .TEText {
  letter-spacing: 0.1px;
  color: #d3caffb2;
}
.TEText {
  position: relative;
  display: block;

  margin-top: auto;
  margin-bottom: auto;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: #ffffffe5;
}

.Pagination {
  position: relative;
  display: flex;

  margin: 0% auto;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
}

.PPagesList {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  flex-shrink: 1;
  flex-grow: 0;
}

.PBPrev,
.PBNext,
.PBIndex {
  position: relative;
  display: flex;

  cursor: pointer;

  width: 52px;
  height: 52px;

  transition: 0.25s;

  border-radius: var(--o-s-global-border-radius);
  background-color: #423b8b;
}
.PBIndex.PBActive {
  background-color: #8f63f0;
}
.PBIndex > span {
  position: relative;
  display: flex;

  margin: auto;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: #e9e9ee;
}

.PBPrevIMG,
.PBNextIMG {
  position: relative;
  display: block;

  width: 100%;
  height: 100%;

  background: url("./../../assets/img/RatingStudent/arrow_back.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.PBNextIMG {
  rotate: 180deg;
}

.DropdownMenu {
  position: absolute;
  display: flex;

  padding: 16px;

  left: 0;
  top: 60px;

  width: 360px;
  height: fit-content;
  max-height: 50svh;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  overflow-y: auto;

  border-radius: var(--o-s-global-border-radius);
  background-color: #8f63f021;

  backdrop-filter: blur(20px);
}
.DDMElem {
  position: relative;
  display: flex;

  padding: 14px 20px;

  width: 100%;
  height: fit-content;

  transition: 0.25s;

  border-radius: var(--o-s-global-border-radius);
  background-color: #423b8b;
}
.DDMElem.Active {
  background-color: #8f63f0;
}

.DDMElem > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: #ffffffe5;
}

.NoStudentsFound {
  position: relative;
  display: block;

  margin: 0% auto;
  padding: 50px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: #ffffffe5;
}
.AutoHyphens {
  hyphens: auto;
  word-wrap: anywhere;
}

.RLink {
  position: relative;
  display: flex;

  padding: 8px 12px;

  width: fit-content;
  height: fit-content;

  flex-direction: column-reverse;
  justify-content: flex-start;
  gap: 4px;
}
.RLink > * {
  margin: 0% auto;
}

.SInputIMG {
  position: relative;
  display: block;

  width: 18px;
  height: 18px;

  background: url("./../../assets/img/ReDesign/interface_icons/search_input_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px;
}
.ShowHideInputM {
  display: none;
  flex-basis: auto;
  height: auto;
  width: fit-content;
  padding: 17px;
}
</style>

<style scoped>
.RemoveFiltersIcon {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../assets/img/ReDesign/interface_icons/close_icon_24px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
}

.Filter.NotEmpty {
  background-color: rgba(123, 88, 213, 1);
}

.MobileCards {
  position: relative;
  display: none;

  margin: 0% auto;

  width: auto;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}
.MCard {
  position: relative;
  display: flex;

  padding: 20px;

  min-width: 265px;
  width: 100%;
  height: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  flex-basis: min-content;
  flex-shrink: 1;
  flex-grow: 1;

  border-radius: var(--o-s-global-border-radius);
  background-color: #292d68cc;
}

.SCity {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;

  color: #ffffff66;
}
.SInfo {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}
.SFIO {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: #ffffffe5;
}
.SAInfo {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: #ffffffe5;
}
.SCompetHeader {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;

  color: #ffffff66;
}
.SCompetList {
  position: relative;
  display: block;

  margin-top: -8px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: #ffffffe5;
}
.SRLink {
  position: relative;
  display: flex;

  margin-top: auto;

  padding: 8px 12px;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;
  flex-wrap: wrap;

  background-color: #423b8b;
  border-radius: var(--o-s-global-border-radius);
}
.SRLink > * {
  margin: auto 0%;
}
.SRLinkIMG {
  position: relative;
  display: block;

  width: 24px;
  height: 24px;

  background: url("./../../assets/img/ReDesign/interface_icons/arrow_link_24px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
}
.SRLinkIMG.Active {
  filter: brightness(0) saturate(100%) invert(84%) sepia(11%) saturate(39%)
    hue-rotate(201deg) brightness(111%) contrast(91%);
}

@media (max-width: 480px) {
  .HeaderTitle {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .MobileCards {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  }
  .ShowHideInputM {
    display: flex;
  }

  .PageContentWrapper {
    margin-bottom: 100px;
  }

  .TableWrapper > *:not(.Filters) {
    display: none;
  }
  .TableWrapper {
    margin-bottom: -8px;
  }

  .HideOnMobileByDefault {
    display: none;
  }

  .DropdownMenu {
    width: 100%;
  }
  .Filter.MFActive:not(.ShowHideInputM) {
    width: 100%;
  }

  .Pagination {
    margin: 0% auto;

    width: fit-content;
    max-width: 100%;

    gap: 8px;

    overflow: hidden;
  }
  .Pagination > *:not(.PPagesList) {
    flex-shrink: 0;
    flex-grow: 0;
  }

  .PPagesList {
    flex-grow: 0;
    overflow: auto;
  }
  .PBIndex {
    flex-shrink: 0;
  }

  .PBPrev,
  .PBNext,
  .PBIndex {
    width: 30px;
    height: 30px;
    border-radius: 8px;
  }
}
</style>

<style scoped>
.Styled-Modal:deep(.SendRequestModal) {
  min-width: 680px;
  width: fit-content;
  background: #1e1b4a;
}
.Offer-Modal {
  position: relative;
  display: flex;

  padding-top: 12px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: auto;
  height: fit-content;
}

.Offer-Modal-Title {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.Offer-Modal-Content {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  min-width: 320px;
  width: auto;
  height: fit-content;
}

.Input-Floating-Label {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;
}

.Input-styled {
  position: relative;
  display: block;

  padding: 12px 8px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  background-color: transparent;

  outline: 0;
  outline-offset: 0;

  border-radius: 4px;
  border: 1px solid #d3caff40;
}
textarea.Input-styled {
  min-height: 120px;
}

.Input-styled::placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #d3caff73;
}
.Input-styled:focus {
  border-color: #656bff;
}
.Input-styled.Input-highligt-error {
  border-color: #ed254e;
}
.Input-styled.Input-highligt-error:focus + .Input-Placeholder,
.Input-styled.Input-highligt-error + .Input-Placeholder {
  color: #ed254e;
}

.Input-Placeholder {
  position: absolute;
  display: block;

  top: 0;
  left: 18px;

  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, -50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Placeholder {
  color: #656bff;
}

.Input-Text-Length {
  position: absolute;
  display: block;

  bottom: 0;
  right: 18px;

  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, 50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Text-Length {
  color: #656bff;
}

.Offer-Modal-Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Button-Next {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: 100%;
  height: fit-content;

  flex-shrink: 1;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff;
}
.Offer-Button-Prev {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  flex-shrink: 0;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff40;
}
.Offer-Button-Prev:hover {
  background-color: rgba(101, 106, 255, 0.144);
}

.Offer-Text-Before-Confirm {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #d3caffb2;
}
.Offer-Text-Before-Confirm .Text-Before-Confirm-Important {
  color: #ffffffe5;
}

.Offer-Modal-Buttons-Child-Wrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Text-Balance-After {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;

  color: #d3caff4d;
}
.Offer-Text-Error {
  position: relative;
  display: block;

  margin-top: 12px;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: none;
  color: #ed254e;
}

select.Input-styled::-ms-expand {
  display: none;
}
select.Input-styled option {
  background-color: white;
  color: black;
}
</style>
